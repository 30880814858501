<template>
  <div class="carousel-add-detail">
    <el-page-header @back="goBack" v-show="type==0" content="知识头条轮播图新增"></el-page-header>
    <el-page-header @back="goBack" v-show="type==1" content="智能问答轮播图新增"></el-page-header>
    <el-page-header @back="goBack" v-show="type==2" content="资源交易轮播图新增"></el-page-header>
    <font class="tip" v-show="type==0" color="#bfbebe">建议图片宽高像素比为2.88</font>
    <font class="tip" v-show="type==1" color="#bfbebe">建议图片宽高像素比为0.84</font>
    <font class="tip" v-show="type==2" color="#bfbebe">建议图片宽高像素比为3</font>
    <div class="info">
      <!-- <span>显示内容:</span> -->
      <el-input v-model="info" placeholder="请输入图片简述"></el-input>
    </div>
    <!-- 圖片回显-->
    <div class="preview-img">
        <div
          class="show-preview"
          :style="{
            width: previews.w + 'px',
            height: previews.h + 'px',
            overflow: 'hidden',
            margin: '5px',
          }"
        >
          <div :style="previews.div">
            <img :src="option.img" style="max-width:500px;height:auto;" />
          </div>
        </div>
      </div>
    <div class="button-block">
      <el-button style="position: relative" type="primary" @click="selectImg"
        >选择图片<label for="selImg"></label
      ></el-button>
      <el-button v-show="status" type="primary" @click="submitCarousel"
        >保存</el-button
      >
      <input type="file" id="selImg" ref="img" @change="selectImg" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: false, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 526,
        autoCropHeight: 367,
        fixedBox: true,
      },
      previews: {},
      info: "",
      status: true,
      type: 0,// 3个板块轮播图的区分标识
    };
  },
  created(){
      // 获取type
      console.log("this.$route.query.type: ",this.$route.query.type);
      this.type = this.$route.query.type;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    realTime(data) {
      this.previews = data;
    },
    //文件上传
    async submitCarousel() {
      // 向服务器上传图片，获取返回路径
    //   let e = document.getElementById('selImg');
    //   console.log("e.target.files[0]",e.target.files[0]);
      let inputDOM = this.$refs.img;
        let file = inputDOM.files[0];
        let params = new FormData();
        params.append('file',file);
        params.append('chunk','0');
        console.log("params.get('file'): ",params.get('file'));
        let config = {
            headers: {'Content-Type': 'multipart/form-data'}
        }
        // 添加请求头
        axios.post(this.api.LoginURL.concat('/resources/trading/resourcesUpload'),params,config)
            .then(async res=>{
                console.log("res-> ",res)
                if(res.data.success){
                    // 调试阶段 先注释 
                    let imgURL = res.data.data.url;
                      let info = await INSERT(
                        "POST",
                        "",
                        ' insert_TtCarousel(objects: {content: "' +
                          this.info +
                          '", img: "'+imgURL+'",type:'+this.type+'}) {     affected_rows   }'
                      );
                      if (info.data.insert_TtCarousel.affected_rows > 0) {
                        this.$message.success("新增成功!");
                        this.goBack();
                      }
                }else{
                    this.$message.warning('服务器繁忙~');
                }
                
            })
            .catch(error=>{
                console.log(error);
                this.$message.warning('服务器错误~');
            })
    },
    selectImg(e) {
      let inputDOM = this.$refs.img;
      console.log("this.$refs.img: ",this.$refs.img);
      console.log("e: ",e);
    //   console.log("e.target.files[0]",e.target.files[0]);
        console.log("inputDOM.files",inputDOM.files)

        console.log("inputDOM.files[0]",inputDOM.files[0])
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        //   console.log('URL.createObjectURL(inputDOM.files[0]);: ',URL.createObjectURL(inputDOM.files[0]))
        this.option.img = URL.createObjectURL(inputDOM.files[0]);
        
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: '格式不正确,请上传图片文件',
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style scoped>
.carousel-add-detail {
}
.add-content {
  margin-top: 10px;
}
.add-content > div {
  display: inline-block;
  vertical-align: middle;
  width: 700px;
  height: 700px;
}
.carousel-add-detail .info {
  padding: 30px 0;
  font-size: 20px;
}
.carousel-add-detail .info input {
  height: 30px;
  width: 800px;
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#selImg {
  display: none;
}

/* private css */
.tip{
  margin-top: 15px;
  display: inline-block;
}
</style>